<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.sysFuncs')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- Name -->
            <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
              <v-text-field
                v-model="myObj.name"
                :label="$vuetify.lang.t('$vuetify.userName')"
                :rules="generalRule"
                outlined
              />
            </v-col>
            <!-- Id -->
            <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
              <v-text-field
                v-model="myObj.code"
                :label="$vuetify.lang.t('$vuetify.ID')"
                :rules="generalRule"
                outlined
              />
            </v-col>
            <!-- classification -->
            <v-col class="py-0" cols="12" lg="4" md="3" sm="4">
              <v-select
                :items="category"
                :item-text="(item) => item.name"
                :item-value="(item) => item.type_code"
                :label="$vuetify.lang.t('$vuetify.classification')"
                v-model="myObj.categoryName"
                outlined
                :rules="generalRule"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>Function
                selection</span
              >
            </v-col>
          </v-row>
          <v-row>
            <!-- view -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox
                v-model="myObj.list"
                :label="$vuetify.lang.t('$vuetify.toView')"
              ></v-checkbox>
            </v-col>
            <!-- add -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox
                v-model="myObj.add"
                :label="$vuetify.lang.t('$vuetify.add')"
              ></v-checkbox>
            </v-col>
            <!-- Delete -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox
                v-model="myObj.del"
                :label="$vuetify.lang.t('$vuetify.Delete')"
              ></v-checkbox>
            </v-col>
            <!-- Edit -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox
                v-model="myObj.modify"
                :label="$vuetify.lang.t('$vuetify.edit')"
              ></v-checkbox>
            </v-col>
            <!-- colum -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox v-model="myObj.column" label="Colum"></v-checkbox>
            </v-col>
            <v-col class="py-0" cols="12" lg="9" md="3" sm="6">
              <v-combobox
                multiple
                v-model="myObj.column_fields"
                deletable-chips
                chips
                :disabled="!myObj.column"
                label="column permission field"
                outlined
                clearable
              ></v-combobox>
            </v-col>
            <!-- Roll -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-checkbox v-model="myObj.row" label="Row"></v-checkbox>
            </v-col>
            <v-col class="py-0" cols="12" lg="9" md="3" sm="6">
              <v-combobox
                multiple
                v-model="myObj.row_fields"
                deletable-chips
                chips
                :disabled="!myObj.row"
                label="Row permission field"
                outlined
                clearable
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />
      <v-btn color="red darken-2" outlined @click="cancelForm">{{
        $vuetify.lang.t("$vuetify.cancel")
      }}</v-btn>
      <v-btn color="green darken-2" @click="validate">{{
        $vuetify.lang.t("$vuetify.save")
      }}</v-btn>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  name: "FuncCRUD",
  props: { dialog: Boolean, myObj: Object, category: Array },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handledata", this.myObj)
        : console.log("false");
    },
    cancelForm() {
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  mounted() {},
};
</script>